










































































import Vue from "vue";
import { Message } from "@/types/complain";
import moment from "moment";

export default Vue.extend({
  name: "ChatBubble",
  props: {
    message: {
      type: Object,
      default: () => ({} as Message),
    },
    currentUser: {
      type: String,
      default: "client",
    },
    hideTime: {
      type: Boolean,
      default: false,
    },
    prevMsg: {
      type: Object,
      default: () => ({} as Message),
    },
    nextMsg: {
      type: Object,
      default: () => ({} as Message),
    },
  },
  computed: {
    bubbleMsg() {
      return this.message.type === "new.message";
    },
    showDateHeader() {
      if (this.prevMsg.type) {
        return (
          this.prevMsg.type === "new.complaint" ||
          moment(this.message.createdAt).format("DD/MM") !==
            moment(this.prevMsg.createdAt).format("DD/MM")
        );
      }
      return false;
    },
    showTimeFooter() {
      if (
        this.nextMsg.type &&
        this.nextMsg?.sentBy?.id === this.message.sentBy.id
      ) {
        return (
          moment(this.message.createdAt).format("HH:mm") !==
          moment(this.nextMsg.createdAt).format("HH:mm")
        );
      }
      return true;
    },
    getBackgroundColor(): string {
      return this.message.sender === "client" ? "primary" : "lightNeutral";
    },
    getTextColor(): string {
      return this.message.sender === "client"
        ? "white--text"
        : "darkNeutral--text text--darken-5";
    },
    dateHeader() {
      return moment(this.message.createdAt).format("Do MMMM YYYY");
    },
    footerTime() {
      return moment(this.message.createdAt).format("HH:mm");
    },
  },
  methods: {
    getInitials(name: any) {
      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
      let initials = [...name.matchAll(rgx)] || [];
      initials = (
        (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
      ).toUpperCase();
      return initials;
    },
    getTime(time: string) {
      return moment(time).startOf("hour").fromNow();
    },
  },
});
