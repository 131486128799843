





























































































import Vue from "vue";
import ComplainOption from "@/components/ComplainOption.vue";
import NewConversation from "@/components/NewConversation.vue";
import ChatForm from "@/components/ChatForm.vue";
import { Emoji } from "@/types/chatbox";
import ChatBox from "@/components/ChatBox.vue";
import { socket } from "@/services/socket";
import { API_STATE_ENUM, RoomEventType } from "@/services/constants";
import { apiClient } from "@/services/axios";
import { MessagesResponse, Message } from "@/types/complain";
import { Tags } from "@/types/organization";
import { SetupParams } from "@/types/misc";

export default Vue.extend({
  name: "Home",
  components: {
    ComplainOption,
    ChatForm,
    ChatBox,
    NewConversation,
  },
  data() {
    return {
      authenticated: false,
      chatStep: 1,
      issue: "",
      currentUser: "User",
      complaint: {} as any,
      socket: {} as any,
      socketID: "",
      clientID: "",
      API_STATE_ENUM,
      apiState: API_STATE_ENUM.IDLE,
      organisationApiState: API_STATE_ENUM.IDLE,
      findOrCreateApiState: API_STATE_ENUM.IDLE,
      startComplaintApiState: API_STATE_ENUM.IDLE,
      clientMessages: { data: [] } as unknown as MessagesResponse,
      organizationData: {} as any,
      selectedTag: {} as Tags,
      params: {} as SetupParams,
    };
  },
  computed: {
    activeComplaint(): Message {
      if (this.clientMessages.data) {
        return this.clientMessages.data[0];
      } else {
        return {} as Message;
      }
    },
    organisationTags(): Tags[] {
      const oldTags = this.organizationData.organizationSetting
        ? this.organizationData.organizationSetting.tags
        : [];
      const orgTags = oldTags.map((tag: Tags) => {
        return {
          ...tag,
          className: oldTags[0] === tag ? "mt-n8" : "",
        };
      });
      return orgTags;
    },
  },
  methods: {
    selectTag(tag: Tags) {
      this.selectedTag = tag;
      this.startComplaint();
    },
    selectEmoji(emoji: Emoji) {
      this.issue += emoji.data;
    },
    updateIssue(issue: string) {
      this.issue = issue;
    },
    sendMessage() {
      const socketMessage = {
        room: `client-${this.organizationData.id}-${this.clientID}`,
        event: "new.message",
        socketId: this.socketID,
        socketType: "client",
        message: {
          body: this.issue,
          complaint: this.activeComplaint?.complaint
            ? this.activeComplaint.complaint
            : this.complaint.id,
          type: RoomEventType.NewMessage,
          sender: "client",
          sentBy: this.clientID,
        },
      };
      console.log("");
      this.socket.emit("on_message", socketMessage, (data: any) => {
        console.log("");
        // this.$set(this.clientMessages.data, 0, data.message);

        this.clientMessages.data = [data.message, ...this.clientMessages.data];
      });
      this.issue = "";
    },
    moveScreen(data: any) {
      this.complaint = data;
      this.chatStep = 2;
    },
    async findOrCreateClient() {
      this.findOrCreateApiState = API_STATE_ENUM.PENDING;
      try {
        const { data } = await apiClient(
          `${this.params.baseUrl}/api`,
          this.params.publicKey
        ).post(`/client`, {
          name: this.params.name,
          email: this.params.email,
          phoneNumber: this.params.phoneNumber,
          clientReference: this.params.clientReference,
        });
        this.clientID = (data as any).data.id;

        this.findOrCreateApiState = API_STATE_ENUM.RESOLVED;
      } catch (error) {
        this.findOrCreateApiState = API_STATE_ENUM.REJECTED;
      }
    },
    async getClientMessages() {
      this.apiState = API_STATE_ENUM.PENDING;
      try {
        const { data } = await apiClient(
          `${this.params.baseUrl}/api`,
          this.params.publicKey
        ).get(`/message/client/${this.clientID}`);
        this.clientMessages = data as any;
        this.apiState = API_STATE_ENUM.RESOLVED;
      } catch (error) {
        this.apiState = API_STATE_ENUM.REJECTED;
      }
    },
    async getOrganizationDetails() {
      this.organisationApiState = API_STATE_ENUM.PENDING;
      try {
        const { data } = await apiClient(
          `${this.params.baseUrl}/api`,
          this.params.publicKey
        ).get(`/organization/widget/details`);
        this.organizationData = (data as any).data;
        this.organisationApiState = API_STATE_ENUM.RESOLVED;
      } catch (error) {
        this.organisationApiState = API_STATE_ENUM.REJECTED;
      }
    },
    async startComplaint() {
      this.startComplaintApiState = API_STATE_ENUM.PENDING;
      try {
        const response = await apiClient(
          `${this.params.baseUrl}/api`,
          this.params.publicKey
        ).post("/complaints", {
          clientReference: this.params.clientReference,
          phoneNumber: this.params.phoneNumber,
          name: this.params.name,
          email: this.params.email,
          tags: this.selectedTag.title ? [this.selectedTag.title] : [""],
        });
        const complaintResponse = (response.data as any).data;
        // this.$emit("move", response.data);
        await this.initSocket(complaintResponse);
        this.authenticated = true;
        this.moveScreen(complaintResponse);

        this.startComplaintApiState = API_STATE_ENUM.RESOLVED;
      } catch (error) {
        this.startComplaintApiState = API_STATE_ENUM.REJECTED;
      }
    },
    async initSocket(data: any) {
      this.socket = socket(
        this.params.publicKey,
        data.client.clientReference,
        this.params.baseUrl
      );

      this.socket.on("connect", () => {
        console.log("socket connected");
        this.socketID = this.socket.id;
      });
      this.socket.on("on_message", (data: any) => {
        this.clientMessages.data = [data.message, ...this.clientMessages.data];
        console.log("message received");
      });
    },
    async loginUser() {
      console.log("");
    },
  },
  created() {
    window.addEventListener(
      "message",
      async ({ data }) => {
        if (data.key !== "PUBLIC_KEY") return;
        this.params = data;
        await this.findOrCreateClient();
        await this.getClientMessages();
        await this.getOrganizationDetails();

        if (data.clientReference) {
          this.authenticated = true;
        } else {
          this.authenticated = false;
        }
      },
      false
    );
  },
  // async mounted() {
  //   // await this.getClientMessages();
  // },
});
