import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=55bc3003&scoped=true&"
import script from "./Home.vue?vue&type=script&lang=ts&"
export * from "./Home.vue?vue&type=script&lang=ts&"
import style0 from "./Home.vue?vue&type=style&index=0&id=55bc3003&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55bc3003",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import RkBadge from '@bit/bento.rothko-design.ui.badge'
import RkPaper from '@bit/bento.rothko-design.ui.paper'
import RkTypography from '@bit/bento.rothko-design.ui.typography'
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {RkBadge,RkPaper,RkTypography,VAvatar,VIcon})
