import { render, staticRenderFns } from "./ChatBubble.vue?vue&type=template&id=aef8425a&scoped=true&"
import script from "./ChatBubble.vue?vue&type=script&lang=ts&"
export * from "./ChatBubble.vue?vue&type=script&lang=ts&"
import style0 from "./ChatBubble.vue?vue&type=style&index=0&id=aef8425a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aef8425a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import RkPaper from '@bit/bento.rothko-design.ui.paper'
import RkTypography from '@bit/bento.rothko-design.ui.typography'
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {RkPaper,RkTypography,VAvatar,VDivider})
