import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
// import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify);

const primaryColor = {
  lighten5: "#E0E9F9",
  lighten4: "#B3C8F0",
  lighten3: "#80A3E6",
  lighten2: "#4D7EDB",
  lighten1: "#2663D4",
  base: "#0047CC",
  darken1: "#0040C7",
  darken2: "#0037C0",
  darken3: "#002FB9",
  darken4: "#0020AD",
};
const secondaryColor = {
  lighten5: "#FBEDE7",
  lighten4: "#F5D3C4",
  lighten3: "#EFB69D",
  lighten2: "#E89876",
  lighten1: "#E38258",
  base: "#DE6C3B",
  darken1: "#DA6435",
  darken2: "#D5592D",
  darken3: "#D14F26",
  darken4: "#C83D19",
};
const warningColor = {
  lighten5: "#FEF4E5",
  lighten4: "#FCE4BD",
  lighten3: "#FAD391",
  lighten2: "#F8C165",
  lighten1: "#F7B344",
  base: "#F5A623",
  darken1: "#F49E1F",
  darken2: "#F2951A",
  darken3: "#F08B15",
  darken4: "#EE7B0C",
};
const errorColor = {
  lighten5: "#FFEEF0",
  lighten4: "#FFD6DB",
  lighten3: "#FFADB8",
  lighten2: "#FF798B",
  lighten1: "#F32843",
  base: "#EE0020",
  darken1: "#DE001E",
  darken2: "#CE001C",
  darken3: "#BE001A",
  darken4: "#AF0017",
};

const successColor = {
  lighten5: "#EAF9EA",
  lighten4: "#CAF0CA",
  lighten3: "#A7E6A7",
  lighten2: "#83DB83",
  lighten1: "#69D469",
  base: "#4ECC4E",
  darken1: "#4ECC4E",
  darken2: "#47C747",
  darken3: "#3DC03D",
  darken4: "#25AD25",
};
const accentColor = {
  lighten5: "#EEEBFD",
  lighten4: "#D4CEF9",
  lighten3: "#B8ADF5",
  lighten2: "#9C8CF1",
  lighten1: "#8674EE",
  base: "#7664EB",
  darken1: "#715BEB",
  darken2: "#6953E9",
  darken3: "#5E49E5",
  darken4: "#422FDD",
};
const darkNeutralColor = {
  lighten5: "#90A0C2",
  lighten4: "#8698BB",
  lighten3: "#647AA8",
  lighten2: "#3F588D",
  lighten1: "#203A6E",
  base: "#001540",
  darken1: "#00123A",
  darken2: "#000F32",
  darken3: "#000C2A",
  darken4: "#000E29",
};
const lightNeutralColor = {
  lighten5: "#FFFFFF",
  base: "#F5F6FA",
  darken1: "#E9EEFA",
  darken2: "#D7E1F6",
  darken3: "#C6D4F2",
  darken4: "#B2C5EE",
};

export default new Vuetify({
  treeShake: true,
  customVariables: ["@/styles/variables.scss"],
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: primaryColor,
        secondary: secondaryColor,
        warning: warningColor,
        error: errorColor,
        success: successColor,
        accent: accentColor,
        info: darkNeutralColor,
        darkNeutral: darkNeutralColor,
        lightNeutral: lightNeutralColor,
      },
      dark: {
        primary: primaryColor,
        secondary: secondaryColor,
        warning: warningColor,
        error: errorColor,
        success: successColor,
        accent: accentColor,
        info: darkNeutralColor,
        darkNeutral: darkNeutralColor,
        lightNeutral: lightNeutralColor,
      },
    },
  },
});
