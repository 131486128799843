<template>
  <RkPaper
    color="lightNeutral lighten-5"
    className="support--chat__container overflow-y"
    :class="customClass"
    rounded="lg"
    :height="height"
  >
    <div class="support--chat__container overflow-y" id="support--chat--widget">
      <!-- <RkTypography
        variant="text-caption"
        class="darkNeutral--text text--lighten-3 text-center mb-5"
        >{{ getDate(Date.now()) }}</RkTypography
      > -->
      <ChatBubble
        v-for="(conversation, index) in allConversations"
        :key="index"
        :prevMsg="
          allConversations[index - 1] ? allConversations[index - 1] : {}
        "
        :nextMsg="
          allConversations[index + 1] ? allConversations[index + 1] : {}
        "
        :hideTime="false"
        :isAdmin="true"
        :message="conversation"
        :currentUser="currentUser"
      />
      <div v-if="complaintResolved">
        <RkPaper color="lightNeutral" class="pa-4 mt-3" rounded="lg">
          <RkTypography
            variant="text-caption"
            class="text-center darkNeutral--text text--lighten-1"
          >
            Were you satisfied with our support on this issue?
          </RkTypography>
          <div class="d-flex align-center justify-center mt-4">
            <div
              v-for="(feedback, index) in feedbackData"
              :class="getActiveClass(feedback.state)"
              :key="index"
              class="feedback--item px-2"
            >
              <v-avatar size="48" @click="selectFeedback(index)">
                <img :src="feedback.img" :alt="feedback.text" />
              </v-avatar>
              <p
                :class="`${
                  feedback.color
                }--text text-center mb-0 font-size-small mt-1 ${
                  feedback.state === 'selected' ? 'font-weight-bold' : ''
                }`"
              >
                {{ feedback.text }}
              </p>
            </div>
          </div>
        </RkPaper>
        <RkTypography
          v-if="completedFeedback"
          variant="text-caption"
          class="text-center darkNeutral--text text--lighten-4 mt-4"
        >
          Thank you for your response!
        </RkTypography>
      </div>
    </div>
  </RkPaper>
</template>

<script>
import Vue from "vue";
import ChatBubble from "@/components/ChatBubble.vue";
import moment from "moment";

export default Vue.extend({
  name: "ChatBox",
  components: {
    ChatBubble,
  },
  props: {
    conversations: {
      type: Array,
      default: () => [],
    },
    currentUser: {
      type: String,
      default: "User",
    },
    height: {
      type: String,
      default: "370",
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: "pa-6",
    },
    complainStatus: {
      type: String,
      default: "active",
    },
  },
  data() {
    return {
      completedFeedback: false,
      feedbackData: [
        {
          img: "https://bento-resources.s3.amazonaws.com/fantastic-feedback.png",
          text: "Fantastic",
          color: "primary",
          state: "none",
        },
        {
          img: "https://bento-resources.s3.amazonaws.com/good-feedback.png",
          text: "Good",
          color: "success",
          state: "none",
        },
        {
          img: "https://bento-resources.s3.amazonaws.com/okay-feedback.png",
          text: "Okay",
          color: "secondary",
          state: "none",
        },
        {
          img: "https://bento-resources.s3.amazonaws.com/terrible-feedback.png",
          text: "Terrible",
          color: "error",
          state: "none",
        },
      ],
    };
  },
  methods: {
    getDate(timeString) {
      return moment(timeString).format("Do MMMM, YYYY");
    },
    getActiveClass(state) {
      let className = "";
      switch (state) {
        case "none":
          className = "";
          break;
        case "selected":
          className = "";
          break;
        case "inactive":
          className = "inactive--class";
          break;
        default:
          className = "";
          break;
      }
      return className;
    },
    selectFeedback(feedback) {
      this.feedbackData.forEach((e, index) => {
        if (feedback !== index) {
          e.state = "inactive";
        } else {
          e.state = "selected";
          this.completedFeedback = true;
        }
      });
      this.$emit("feedback");
    },
    scrollToBottom() {
      setTimeout(() => {
        document.getElementById("support--chat--widget").scrollTop =
          document.getElementById("support--chat--widget").scrollHeight;
      }, 50);
      // goTo('support--chat')
    },
  },
  computed: {
    allConversations() {
      const conv = this.conversations.map((c) => c).reverse();
      return conv;
    },
    complaintResolved() {
      // coditions for complaint resolution
      return false;
    },
  },
  watch: {
    allConversations: {
      deep: true,
      handler() {
        this.scrollToBottom();
      },
    },
  },
  mounted() {
    this.scrollToBottom();
  },
});
</script>

<style lang="scss" scoped>
.support--chat__line {
  background-color: #90a0c2;
  height: 0.5px;
}
.overflow-y {
  overflow-y: scroll !important;
}
.inactive--class {
  opacity: 0.2;
}
.feedback--item {
  &:hover {
    cursor: pointer;
  }
}
.font-size-small {
  font-size: 10px !important;
}
</style>
