









































import Vue from "vue";
import { VEmojiPicker } from "v-emoji-picker";
import { Emoji } from "@/types/chatbox";

export default Vue.extend({
  name: "ChatForm",
  components: {
    VEmojiPicker,
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      issue: "",
    };
  },
  methods: {
    initMessage(): void {
      this.$emit("init");
      this.issue = "";
    },
    selectEmoji(emoji: Emoji) {
      this.$emit("emoji", emoji);
      this.issue += emoji.data;
    },
  },
});
