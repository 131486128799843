import io from "socket.io-client";
// import * as io from 'socket.io-client'

// export const socket = (io as any)("https://devsupportapi.bento.africa", {
//   reconnectionDelayMax: 10000,
//   extraHeaders: {
//     public_key: "pk_nlpsgyovebteiraeagbmznluvxusgvqsailoyshd",
//     client_reference: "31083e3a-f25f-4ab6-a95c-81dbb33bde7c",
//   },
//   transportOptions: {
//     polling: {
//       extraHeaders: {
//         public_key: "pk_nlpsgyovebteiraeagbmznluvxusgvqsailoyshd",
//         client_reference: "31083e3a-f25f-4ab6-a95c-81dbb33bde7c",
//       },
//     },
//     websocket: {
//       extraHeaders: {
//         public_key: "pk_nlpsgyovebteiraeagbmznluvxusgvqsailoyshd",
//         client_reference: "31083e3a-f25f-4ab6-a95c-81dbb33bde7c",
//       },
//     },
//   },
// });

export const socket = (
  public_key: string,
  client_reference: string,
  base_url: string
): any => {
  return (io as any)(
    `${base_url}?public_key=${public_key}&client_reference=${client_reference}`,
    {
      reconnectionDelayMax: 10000,
      transportOptions: {
        websocket: {
          extraHeaders: {
            public_key,
            client_reference,
          },
        },
      },
      transports: ["websocket"],
    }
  );
};
