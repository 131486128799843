import axios, { AxiosInstance } from "axios";

export const apiClient = (baseUrl: string, publicKey: string): AxiosInstance =>
  axios.create({
    baseURL: baseUrl,
    withCredentials: false,

    headers: {
      public_key: publicKey,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
