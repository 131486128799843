<template>
  <RkPaper color="lightNeutral lighten-5" className="pa-5" rounded="lg">
    <div>
      <RkTypography
        variant="text-caption"
        class="darkNeutral--text text--lighten-3 text-center mb-5"
        >{{ getDate(Date.now()) }}</RkTypography
      >
      <ChatBubble
        :isAdmin="true"
        :message="welcomeMessage"
        :currentUser="currentUser"
      />
      <RkPaper
        color="lightNeutral"
        rounded="lg"
        class="darkNeutral--text py-5 px-4 mt-4 mb-2"
      >
        <div class="d-flex justify-space-between align-center">
          <RkTypography variant="text-body-1" class="font-weight-bold">
            Are you an Existing User?
          </RkTypography>
          <RkButton color="primary"> Login </RkButton>
        </div>
        <RkTypography variant="text-caption" class="mt-2">
          Personalize your complaints to your organization
        </RkTypography>
      </RkPaper>
      <RkPaper
        color="lightNeutral"
        rounded="lg"
        class="darkNeutral--text py-5 px-4 mt-5"
      >
        <div class="d-flex justify-space-between align-center">
          <RkTypography variant="text-body-1" class="font-weight-bold">
            New User
          </RkTypography>
          <RkButton
            color="primary"
            outlined
            :loading="newComplaintStatus === API_STATE_ENUM.PENDING"
            @click="$emit('new-complaint')"
            >Start a conversation</RkButton
          >
        </div>
        <RkTypography variant="text-caption" class="mt-2">
          I just want to have a conversation
        </RkTypography>
      </RkPaper>
    </div>
  </RkPaper>
</template>

<script>
import Vue from "vue";
import ChatBubble from "@/components/ChatBubble.vue";
import moment from "moment";
import { API_STATE_ENUM } from "@/services/constants";

export default Vue.extend({
  name: "NewConversation",
  components: {
    ChatBubble,
  },
  props: {
    newComplaintStatus: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      API_STATE_ENUM,
      currentUser: "new",
      welcomeMessage: {
        body: "Hi 👋🏾 My Name is Kofo, from Bento support. Thank you for reaching out and how can I help you?",
        createdAt: Date.now(),
        id: "2",
        sender: "default",
        sentBy: {
          id: "615ffb535a30520725ca2c59",
          name: "Francis Gleason",
          profileImageUrl:
            "https://bento-resources.s3.amazonaws.com/illustrations/avatar-kofo.png",
        },
        type: "new.message",
        updatedAt: Date.now(),
        _id: "616346b1440c226750b32f05",
      },
    };
  },
  methods: {
    getDate(timeString) {
      return moment(timeString).format("Do MMMM, YYYY");
    },
  },
});
</script>

<style></style>
