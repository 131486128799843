<template>
  <RkPaper
    rounded="lg"
    className="mt-4 pa-6 d-flex align-center justify-space-between support--chat-option"
    color="lightNeutral"
    @click="$emit('select-tag', option)"
  >
    <RkTypography
      variant="text-body-1"
      class="darkNeutral--text text--darken-5"
      >{{ option.title }}</RkTypography
    >
    <v-progress-circular
      v-if="apiState === API_STATE_ENUM.PENDING"
      color="primary"
      indeterminate
    ></v-progress-circular>

    <v-icon v-else color="darkNeutral darken-5">mdi-arrow-right</v-icon>
  </RkPaper>
</template>

<script>
import Vue from "vue";
import { apiClient } from "@/services/axios";
import { API_STATE_ENUM } from "@/services/constants";

export default Vue.extend({
  name: "ComplainOption",

  props: {
    option: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      API_STATE_ENUM,
      apiState: API_STATE_ENUM.IDLE,
    };
  },
  methods: {
    async startComplaint() {
      this.apiState = API_STATE_ENUM.PENDING;
      try {
        const response = await apiClient.post("/complaints", {
          clientReference: "31083e3a-f25f-4ab6-a95c-81dbb33bde7c",
          phoneNumber: "08109819775",
          name: "Francis Gleason",
          email: "Eulah.Rohan43@hotmail.com",
          tags: [this.option.title],
        });
        this.$emit("move", response.data);
        this.apiState = API_STATE_ENUM.RESOLVED;
      } catch (error) {
        this.apiState = API_STATE_ENUM.REJECTED;
      }
    },
  },
});
</script>
