export const API_STATE_ENUM = {
  IDLE: "idle",
  PENDING: "pending",
  RESOLVED: "resolved",
  REJECTED: "rejected",
};

export enum RoomEventType {
  ComplaintResolved = "complaint.resolved",
  ComplaintRated = "complain.rated",
  NewComplaint = "complaint.new",
  NewMessage = "new.message",
  JoinedRoom = "room.joined",
  LeftRoom = "room.left",
  MessageRead = "message.read",
  MessageDelivered = "message.delivered",
}
